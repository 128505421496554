import { useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { LoadingButton } from "@mui/lab";

import { Error } from "../../../shared/ui/Error";
import { useDeleteDeal } from "../dealAPISlice";


export const DeleteDealButton = ({ id }: { id: string }) => {
    const [deleteDeal, metadata] = useDeleteDeal();

    const navigate = useNavigate();
    useEffect(() => {
        if (metadata.isSuccess) {
            navigate('/deals');
        }
    }, [metadata.isSuccess]);

    return (
        <>
            {metadata.error ? <Error message={`${metadata.error}`} /> : null}
            <LoadingButton
                variant="outlined"
                color="error"
                sx={{
                    'margin-left': 'auto'
                }}

                onClick={() => deleteDeal(id)}
                loading={metadata.isLoading}
            >
                Удалить сделку
            </LoadingButton>
        </>
    )
}