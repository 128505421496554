import { MenuItem, Select, TextField } from "@mui/material";
import './DateInput.css';
import { getTimeFormat } from "../../../../shared/helpers/timeFormat";
import { useEffect, useState } from "react";


const MONTHS = [
    'Январь', 'Февраль', 'Март', 'Апрель',
    'Май', 'Июнь', 'Июль', 'Август',
    'Сентябрь', 'Октябрь', 'Ноябрь', 'Декабрь'
]


const startYear = 2010;
const currentYear = new Date().getFullYear();
const years = new Array(currentYear - startYear)
    .fill(0)
    .map((_, index) => currentYear - index);



export function DateInput({
    value, setValue
}: {
    value: Date,
    setValue?: (newDate: Date) => any
}) {
    const [day, setDay] = useState(`${value.getDate()}`);

    useEffect(() => {
        setDay(`${value.getDate()}`)
    }, [value.getDate()]);

    return (
        <div
            className="date_input"
        >
            <TextField
                InputProps={{
                    sx: {
                        borderEndEndRadius: 0,
                        borderTopRightRadius: 0,
                    },
                }}
                InputLabelProps={{ shrink: true }}
                value={day}
                onChange={e => {
                    const newDay = e.target.value.match(/\d+/)?.[0];
                    if (!newDay) {
                        setDay('');
                        return;
                    }
                    if (newDay === day) {
                        return;
                    }

                    const nextDate = new Date(value);
                    nextDate.setMonth(value.getMonth() + 1);
                    nextDate.setDate(0);
                    const lastDayOfMonth = nextDate.getDate();

                    if (Number(newDay) <= lastDayOfMonth) {
                        setDay(newDay);
                    } else {
                        setDay(`${lastDayOfMonth}`);
                    }
                }}
                onBlur={() => {
                    const newDate = new Date(value);
                    if (day === '') {
                        newDate.setDate(1);
                        setDay('1');
                    } else {
                        newDate.setDate(Number(day));
                    }
                    setValue?.(newDate);
                }}
            />
            <Select
                label="Месяц"
                sx={{
                    borderRadius: 0,
                    width: '130px'
                }}
                MenuProps={{
                    sx: {
                        height: '300px'
                    }
                }}
                value={value?.getMonth()}
                onChange={e => {
                    const newDate = new Date(value);
                    newDate.setMonth(Number(e.target.value));
                    setValue?.(newDate);
                }}
            >
                {
                    MONTHS.map((month, index) => (
                        <MenuItem
                            value={index}
                            key={month}
                        >
                            {month}
                        </MenuItem>
                    ))
                }
            </Select>
            <Select
                label="Год"
                sx={{
                    borderRadius: 0,
                }}
                MenuProps={{
                    sx: {
                        height: '300px'
                    }
                }}
                value={value?.getFullYear()}
                onChange={e => {
                    const newDate = new Date(value);
                    newDate.setFullYear(Number(e.target.value));
                    setValue?.(newDate);
                }}
            >
                {
                    years.map((year) => (
                        <MenuItem
                            value={year}
                            key={year}
                        >
                            {year}
                        </MenuItem>
                    ))
                }
            </Select>
            <TextField
                type="time"
                InputProps={{
                    sx: {
                        borderTopLeftRadius: 0,
                        borderEndStartRadius: 0
                    },
                }}
                InputLabelProps={{ shrink: true }}

                value={getTimeFormat(value)}
                onChange={e => {
                    const newDate = new Date(value);
                    const [hours, minutes] = e.target.value.split(':');
                    newDate.setHours(Number(hours));
                    newDate.setMinutes(Number(minutes));
                    setValue?.(newDate);
                }}
            />
        </div>
    )
}