export const Error = (props) => {
    return (
        <p
            style={{
                'color': '#bf0000',
                'margin': '0 0 10px 0'
            }}
        >
            {props.message}
        </p>
    )
}