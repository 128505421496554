import { Button, Card } from "@mui/material";
import { useNavigate } from "react-router-dom";

import { Client } from "../client.dto";
import './ClientCard.css';


export const ClientCard = ({ client }: { client: Client }) => {
    const navigate = useNavigate();

    return (
        <Card className="card">
            <h3>{client.name}</h3>
            <div className="card-description">
                <div className="code">{client.code}</div>
                <Button
                    className="open-client"
                    onClick={() => {
                        navigate(`${client.code}`)
                    }}
                >
                    Открыть
                </Button>
            </div>
        </Card>
    )
}