import { useState } from "react";
import { Autocomplete, TextField } from "@mui/material";

import { useFetchAllClients } from "../clientAPISlice";
import { Instrument } from "../../deals/deal.dto";


export const ClientCodeInput = (props: any) => {
    const { data } = useFetchAllClients({});
    const clientOptions = data?.map(client => client.code);

    const [inputValue, setInputValue] = useState('');

    
    return (
        <Autocomplete
            options={clientOptions ?? []}
            {...props}
            getOptionLabel={option => option}

            value={props.value}
            onChange={(e, value: Instrument) => props.onChange(value)}

            inputValue={inputValue}
            onInputChange={(e, newInputValue) => setInputValue(newInputValue.toUpperCase())}

            renderInput={(params) =>
                <TextField
                    {...params}
                    label={props?.label}
                    error={props?.error}
                    onBlur={props?.onBlur}
                    helperText={props?.helperText}
                    sx={{
                        minWidth: '200px'
                    }}
                />}
        />
    )
}