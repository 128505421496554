
export interface Client {
    address: {
        locationAddress: string,
        registrationAddress: string,
    },
    bankDetails: {
        account: string,
        corporateAccount: string,
        inn: string,
        kpp: string,
        ogrn: string,
        rcbic: string,
    },
    code: string,
    email: string,
    name: string,
}

export interface FormClient {
    locationAddress: string,
    registrationAddress: string,
    account: string,
    corporateAccount: string,
    inn: string,
    kpp: string,
    ogrn: string,
    rcbic: string,
    code: string,
    email: string,
    name: string,
}

export function mapClientToClientForm(client: Client): FormClient {
    return {
        locationAddress: client.address.locationAddress,
        registrationAddress: client.address.registrationAddress,
        account: client.bankDetails.account,
        corporateAccount: client.bankDetails.corporateAccount,
        inn: client.bankDetails.inn,
        kpp: client.bankDetails.kpp,
        ogrn: client.bankDetails.ogrn,
        rcbic: client.bankDetails.rcbic,
        code: client.code,
        email: client.email,
        name: client.name,
    }
}

export function mapClientFormToClient(clientForm: FormClient): Client {
    return {
        address: {
            locationAddress: clientForm.locationAddress,
            registrationAddress: clientForm.registrationAddress,
        },
        bankDetails: {
            account: clientForm.account,
            corporateAccount: clientForm.corporateAccount,
            inn: clientForm.inn,
            kpp: clientForm.kpp,
            ogrn: clientForm.ogrn,
            rcbic: clientForm.rcbic,
        },
        code: clientForm.code,
        email: clientForm.email,
        name: clientForm.name,
    }
}