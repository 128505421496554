import { Outlet, useLocation, useNavigate } from "react-router-dom"
import { useEffect } from "react";

import { useSaveURL } from "../../shared/helpers/useSaveURL";
import { useAppSelector } from "../../app/storeHooks";


export const useRedirectOnPasswordUnchaged = () => {
    const location = useLocation();
    const navigate = useNavigate();
    const isPasswordChanged = useAppSelector(state => state.auth.isPasswordChanged);

    useEffect(() => {
        if (!isPasswordChanged) {
            if (!location.pathname.includes('settings')) {
                navigate('settings');
            }
        }
    }, [isPasswordChanged, location]);
}


export const Main = () => {
    useRedirectOnPasswordUnchaged();
    useSaveURL();

    return (
        <main
            style={{
                overflowX: 'auto',
                width: '100%'
            }}
        >
            <Outlet />
        </main>
    )
}