import { ArrowBack } from "@mui/icons-material"
import { IconButton } from "@mui/material"
import { useNavigate } from "react-router-dom";

import { AddClientForm } from "../../features/client/ui/AddClientForm";


export const AddClientWidget = () => {
    const navigate = useNavigate();

    return (
        <>
            <IconButton
                onClick={() => {
                    navigate('/clients');
                }}
                >
                <ArrowBack />
            </IconButton>
            <AddClientForm />
        </>
    )
}