import { IconButton } from "@mui/material";
import AccountCircleIcon from '@mui/icons-material/AccountCircle';
import { useNavigate } from "react-router-dom";

export const SettingsButton = () => {
    const navigate = useNavigate();

    return(
        <IconButton
            onClick={() => navigate('settings')}
        >
            <AccountCircleIcon />
        </IconButton>
    )
}