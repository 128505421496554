import { AsyncThunk } from "@reduxjs/toolkit";
import { useState } from "react";

import { useAppDispatch } from "../../app/storeHooks";
import { useDispatch } from "react-redux";


export const useRequest = (requestAction: (params: any) => any) => {
    const [isLoading, setIsLoading] = useState(false);
    const [response, setResponse] = useState(null);
    const [error, setError] = useState(null);

    const dispatch = useDispatch();

    function sendRequest(params: any) {
        setIsLoading(true);
        dispatch(requestAction(params))
            .then((res: any) => {
                setResponse(res);
                if (res && res.error) {
                    setError(res.error?.message);
                } else {
                    setError(null);
                }
            })
            .finally(() => {
                setIsLoading(false);
            })
    }

    return [sendRequest, isLoading, response, error];
}

export const useRequestMetadata = (requestAction: (params: any) => Promise<any>) => {
    const [isLoading, setIsLoading] = useState(false);
    const [response, setResponse] = useState(null);
    const [error, setError] = useState(null);

    function sendRequest(params: any) {
        setIsLoading(true);
        requestAction(params)
            .then(res => {
                setResponse(res);
                if (res && res.error) {
                    setError(res.error?.message);
                } else {
                    setError(null);
                }
            })
            .catch(error => {
                setError(error?.message);
            })
            .finally(() => {
                setIsLoading(false);
            });
    }

    return {sendRequest, isLoading, response, error};
}


export const useDispatchRequest = (action: AsyncThunk<any, any, any>) => {
    const dispatch = useAppDispatch();
    const metadata = useRequestMetadata((params: any) => dispatch(action(params)));

    return metadata;
}