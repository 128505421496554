import { ChangePasswordForm } from "../../features/ChangePasswordForm/ChangePasswordForm"


export const SettingsWidget = () => {
    return (
        <>
            <h2>Настройки</h2>
            <ChangePasswordForm />
        </>
    )
}