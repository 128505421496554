import './App.css';
import { useEffect } from 'react';
import { RouterProvider } from 'react-router-dom';

import { appRouter } from '../processes/appRouters';
import { useAppDispatch } from './storeHooks';
import { checkToken } from '../features/authentication/authHelpers';


function App() {
  const dispatch = useAppDispatch();
  useEffect(() => {
    checkToken(dispatch);
  }, []);

  return (
    <div className="App">
      <RouterProvider router={appRouter} />
    </div>
  );
}

export default App;