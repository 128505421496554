import { FC } from "react";
import { Paper } from "@mui/material";

import { BrokerInfoProvider } from "../BrokerInfoCoomponent/ui/BrokerInfoProvider";
import { SettingsButton } from "../../widgets/Settings/SettingsButton";
import { NavBar } from "./Nav";
import { Main } from "./Main";
import './MainAppLayout.css';


export function MainAppLayout({
    renderHeader,
    renderNav,
    renderMain,
}: {
    renderHeader: FC,
    renderNav: FC,
    renderMain: FC,
}) {
    return (
        <div
            style={{
                display: 'flex',
                flexDirection: 'column',
                minHeight: '100vh'
            }}
        >
            {renderHeader({})}
            <Paper
                className="content"
                elevation={2}
                square={false}
                sx={{
                    margin: '5px',
                    flexGrow: '2'
                }}
            >
                {renderNav({})}
                {renderMain({})}
            </Paper>
        </div>
    )
}


export const MainApp = () => (<MainAppLayout
    renderHeader={() => <Header />}
    renderNav={() => <NavBar />}
    renderMain={() => <Main />}
/>)


export const Header = () => {
    return (
        <Paper
            square={false}
            sx={{
                margin: '0 5px',
            }}
        >
            <header>
                <h1>DealDone</h1>
                <div style={{
                    display: 'flex',
                    alignItems: 'center'
                }}>
                    <BrokerInfoProvider />
                    <SettingsButton />
                </div>
            </header>

        </Paper>
    )
}