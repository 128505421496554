import api, { API_URL } from '../../shared/api/request';
import axios, { AxiosError } from 'axios';

const LOGIN_ENDPOINT = 'authentication/login';
const REFRESH_ENDPOINT = 'authentication/refresh';
const CHANGE_PASSWORD_ENDPOINT = 'authentication/change-password';


export const loginRequest = async function (email: string, password: string): Promise<LoginResponse> {
    return api.post<LoginResponse>(LOGIN_ENDPOINT, {
        'email': email,
        'password': password
    })
        .then(response => response.data)
        .catch((e: Error | AxiosError) => {
            if (e instanceof AxiosError) {
                throw new Error(
                    mapLoginErrorCodeToRuMessage(e.response?.data)
                )
            }

            throw new Error(e.message);
        });
};

const mapLoginErrorCodeToRuMessage = (error: ErrorResponseData) => {
    switch (error.errorCode) {
        case 4000:
            return "Невалидный email";
        case 4041:
            return "Пользователь с таким email и паролем не найден";
        default:
            return error.errorDescription;
    }
}


export const refreshRequest = async function (): Promise<LoginResponse> {
    const axiosCheckAuthRequest = axios.create({
        withCredentials: true,
        baseURL: API_URL,
    });
    return axiosCheckAuthRequest.post<LoginResponse>(
        REFRESH_ENDPOINT,
    )
        .then(response => response.data)
        .catch(response => {
            return response;
        });
};


export const changePasswordRequest = async function (password: string) {
    return api.post(CHANGE_PASSWORD_ENDPOINT,
        {
            'newPassword': password
        },
    )
        .then(response => response.data)
        .catch((e: Error | AxiosError) => {
            if (e instanceof AxiosError) {
                throw new Error(
                    mapChangePasswordErrorCodeToRuMessage(e?.response?.data)
                )
            }

            throw new Error(e.message);
        })
};

const mapChangePasswordErrorCodeToRuMessage = (error: ErrorResponseData) => {
    switch (error.errorCode) {
        case 4003:
            return 'Слабый пароль.';
        case 4004:
            return "Пользователь не был зарегистрирован.";
        default:
            return error.errorDescription;
    }
}