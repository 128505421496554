import { createAsyncThunk, createSlice } from "@reduxjs/toolkit"
import { getBrokerInfoRequest } from './../api/brokerService.js';

const initialState = {
    id: '',
    name: '',
    lastName: '',
    email: '',
    isLoaded: false,
}

export const getBrokerInfo = createAsyncThunk(
    'broker/getBrokerInfo',
    async (params, thunkAPI) => {
        const response = await getBrokerInfoRequest();
        if (!response) {
            return thunkAPI.rejectWithValue('Error');
        } else {
            return response;
        }
});

export const brokerSlice = createSlice({
    name: 'broker',
    initialState,
    extraReducers: builder => {
        builder
            .addCase(getBrokerInfo.fulfilled, (state, action) => {
                state.id = action.payload.id;
                state.name = action.payload.name;
                state.lastName = action.payload.lastName;
                state.email = action.payload.email;
                state.isLoaded = true;
            })
            .addCase(getBrokerInfo.rejected, (state, action) => {
            })
    },
});

export default brokerSlice.reducer;