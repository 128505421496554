import { BaseQueryFn, FetchArgs, FetchBaseQueryError, fetchBaseQuery } from "@reduxjs/toolkit/query";
import CONFIG from './../appConfig.json';
import { checkAuth, logoutUser } from "../features/authentication/auth.slice";

export function setAuthorizationHeaders(headers: Headers) {
    headers.append('Authorization', `Bearer ${localStorage.getItem('token')}`)
    return headers;
}

const baseQuery = fetchBaseQuery({
    baseUrl: CONFIG.serverAddress,
    credentials: 'include',
    prepareHeaders(headers) {
        return setAuthorizationHeaders(headers);
    }
});

export const baseQueryWithReauth: BaseQueryFn<
    string | FetchArgs,
    unknown,
    FetchBaseQueryError
> = async (args, api, extraOptions) => {
    let result = await baseQuery(args, api, extraOptions)

    if (result.error && result.error.status === 401) {
        try {
            const refreshResult = await api.dispatch(checkAuth());
            console.log(refreshResult);

            result = await baseQuery(args, api, extraOptions);
            
        } catch (e) {
            console.log(e);
            api.dispatch(logoutUser());
        }
    }

    return result;
}