import { createApi } from '@reduxjs/toolkit/query/react';

import { Client } from './client.dto';
import { ResponseError } from '../../api/api.dto';
import { baseQueryWithReauth } from '../../api/apiHelpers';
import { mapErrorToRuMessage } from './clientHelpers';


export const clientAPI = createApi({
    reducerPath: 'clientAPI',
    baseQuery: baseQueryWithReauth,
    tagTypes: ['Client'],
    endpoints: (builder) => ({

        fetchClient: builder.query<Client, string>({
            query: (code: string) => ({
                url: `client/${code}`,
                method: 'GET'
            })
        }),

        fetchAllClients: builder.query<Client[], unknown>({
            query: () => ({
                url: `clients`,
                method: 'GET'
            }),
            transformErrorResponse: (
                response,
                meta,
                arg
            ) => response.data,
            providesTags: () => ['Client']
        }),

        addNewClient: builder.mutation<unknown, Client>({
            query: (client: Client) => ({
                url: 'client',
                method: 'POST',
                body: client
            }),
            transformResponse: (response, meta, arg) => response,
            transformErrorResponse: (
                response,
                meta,
                arg
            ) => mapErrorToRuMessage(response.data as ResponseError),
            invalidatesTags: () => ['Client']
        }),

        changeClient: builder.mutation<unknown, { code: string, client: Client }>({
            query: ({ code, client }: { code: string, client: Client }) => ({
                url: `client/${code}`,
                method: 'PATCH',
                body: client
            }),
            transformErrorResponse: (
                response,
                meta,
                arg
            ) => mapErrorToRuMessage(response.data as ResponseError),
            invalidatesTags: () => ['Client']
        }),

        deleteClient: builder.mutation<unknown, string>({
            query: (code: string) => ({
                url: `client/${code}`,
                method: 'DELETE'
            }),
            invalidatesTags: () => ['Client']
        })
    })
})


export const useFetchClient = clientAPI.endpoints.fetchClient.useQuery;
export const useFetchAllClients = clientAPI.endpoints.fetchAllClients.useQuery;
export const useAddNewClient = clientAPI.endpoints.addNewClient.useMutation;
export const useChangeClient = clientAPI.endpoints.changeClient.useMutation;
export const useDeleteClient = clientAPI.endpoints.deleteClient.useMutation;