import { useDispatch, useSelector } from "react-redux"
import { BrokerInfoComponent } from "./BrokerInfoComponent"
import { getBrokerInfo } from './../model/brokerSlice.js';
import { useEffect } from "react";

export const BrokerInfoProvider = () => {
    const dispatch = useDispatch();
    useEffect(() => {
        dispatch(getBrokerInfo());
    }, []);

    return (
        <BrokerInfoComponent />
    )
}