import api from "../../shared/api/request";

const GET_EXCHANGE_RATE = (currency: string) => {
    return `ExchangeRate/${currency}`;
}

const handleDate = (dateJSON: string) => {
    const date = new Date(dateJSON);
    return `${date.getFullYear()}.${date.getMonth() + 1}.${date.getDate()}`;
}

export const getExchangeRateRequest = async (currency: string, date: string) => {
    const dateByFormat = handleDate(date);

    return api.get(
        GET_EXCHANGE_RATE(currency),
        {
            params: {
                date: dateByFormat
            }
        }
    )
        .then(response => response.data)
        .catch(e => {
            throw e?.response?.data
        });
}