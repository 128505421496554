import { TextField } from "@mui/material";
import { LoadingButton } from "@mui/lab";

import * as Yup from 'yup';
import { useFormik } from "formik";

import './LoginForm.css';
import { loginUser } from "../auth.slice";
import { useDispatchRequest } from "../../../shared/WithRequestComponent/useRequest";
import { Error } from '../../../shared/ui/Error';
import { PasswordInput } from "../../../shared/ui/PasswordInput";


const initialValues: LoginCredentials = {
    email: '',
    password: '',
}

const validationSchema = Yup.object({
    email: Yup.string()
        .email('Неправильный email адрес.')
        .required('Введите email адрес.'),
    password: Yup.string()
        .required('Введите пароль.')
});


const LoginForm = () => {
    const { sendRequest, isLoading, response, error } = useDispatchRequest(loginUser);

    const formik = useFormik({
        initialValues,
        validationSchema,
        onSubmit: values => sendRequest(values)
    });

    return (
        <form className="LoginForm" onSubmit={formik.handleSubmit}>
            <TextField
                type="email"
                id="email"
                name="email"
                placeholder="Email"
                label="Email"
                fullWidth
                required
                margin="normal"
                value={formik.values.email}
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                error={!!formik.touched.email && !!formik.errors.email}
                helperText={formik.touched.email ? formik.errors.email : null}
            />
            <PasswordInput
                id="password"
                name="password"
                placeholder="Пароль"
                label="Пароль"
                fullWidth
                required
                margin="normal"
                value={formik.values.password}
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                error={!!formik.touched.password && !!formik.errors.password}
                helperText={formik.touched.password ? formik.errors.password : null}

            />

            <LoadingButton
                type='submit'
                loading={isLoading}
                variant='contained'
                fullWidth
                sx={{
                    'marginTop': '16px',
                    'marginBottom': '8px'
                }}
            >
                Войти
            </LoadingButton>

            {error ? <Error message={`${error}`} /> : null}

        </form>
    )
}

export default LoginForm;