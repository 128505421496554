import axios from "axios";
import { checkAuth, setPasswordWasNotChanged } from "../../features/authentication/auth.slice";

const CONFIG = require("./../../appConfig.json");

export const API_URL = CONFIG.serverAddress;

const api = axios.create({
    withCredentials: true,
    baseURL: API_URL,
});

api.interceptors.request.use(config => {
    config.headers.Authorization = `Bearer ${localStorage.getItem('token')}`
    return config;
});

let store;

export const injectStore = _store => {
    store = _store;
}

api.interceptors.response.use(config => {
    return config;
}, async error => {
    const originalRequest = error.config;
    if (error?.response?.status === 401 && error.config && !error.config._isRetry) {
        originalRequest._isRetry = true;
        try {
            const response = await store.dispatch(checkAuth());
            return api.request(originalRequest);
        } catch (e) {
        }
    }
    if (error?.response?.status === 403 && error.config) {
        if (error?.response?.data.errorCode === 4031) {
            store.dispatch(setPasswordWasNotChanged());
            return;
        }
    }
    throw error
})

export default api;