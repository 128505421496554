import { IconButton, InputAdornment, TextField } from "@mui/material";
import ClearIcon from '@mui/icons-material/Clear';
import SearchIcon from '@mui/icons-material/Search';
import { useState } from "react";

export const useSearch = (
    searchFilterFunction
) => {
    const [input, setInput] = useState('');

    function filter(array) {
        return array?.filter(value => searchFilterFunction(value, input));
    }

    return {
        input,
        setInput,
        filter
    }
}

export const useSearchByNameAndCode = () => {
    return useSearch(
        (value, valueToSearch) => {
            const name = value?.name.toLowerCase();
            const code = value?.code.toLowerCase();

            return name?.includes(valueToSearch?.toLowerCase())
                || code?.includes(valueToSearch?.toLowerCase());
        }
    )
}

export const SearchInput = ({ input: { input, setInput }, ...otherProps }) => {
    return (
        <TextField
            {...otherProps}
            value={input}
            onChange={e => {
                setInput(e.target.value);
            }}

            sx={{
                'width': '30em'
            }}
            placeholder="Поиск..."

            InputProps={{
                endAdornment:
                    <InputAdornment position="end">
                        {
                            input
                                ? <IconButton
                                    onClick={e => setInput('')}
                                >
                                    <ClearIcon />
                                </IconButton>
                                : null
                        }

                    </InputAdornment>
                ,
                startAdornment:
                    <InputAdornment position="start">
                        <SearchIcon />
                    </InputAdornment>

            }}
        />
    )
}