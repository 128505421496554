import { ResponseError } from "../../api/api.dto";

export const mapErrorToRuMessage = (error: ResponseError) => {
    switch (error.errorCode) {
        case 4000:
            if (error.errorDescription.includes("INN")) {
                return "Некорректный ИНН.";
            } if (error.errorDescription.includes("OGRN")) {
                return "Некорректный ОГРН.";
            }
        case 4092:
            return "Клиент уже был добавлен.";
        default:
            return error.errorDescription;
    }
}

