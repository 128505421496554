import { ResponseError } from "../../api/api.dto";

export function mapDealErrorToRuMessage(error: ResponseError) {
    switch (error.errorCode) {
        case 4001:
            return "Сделка не может заключена между одним клиентом."
        case 4002:
            if (error.errorDescription.includes('TOD')) {
                return "У сделки с инструментом TOD дата валютирования должна быть датой заключения.";
            } else if (error.errorDescription.includes('TOM')) {
                return "У сделки с инструментом TOM дата валютирования должна быть следующим днем даты заключения.";
            } else if (error.errorDescription.includes('SPOT')) {
                return "У сделки с инструментом SPOT дата валютирования должна быть послезавтра дня даты заключения.";
            }
        default:
            return error.errorDescription;
    }
}