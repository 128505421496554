import { Button } from "@mui/material"
import CloudUploadIcon from '@mui/icons-material/CloudUpload';
import { styled } from '@mui/material/styles';
import { useState } from "react";
import { uploadInstrumentRequest } from "./instrumentService";

const VisuallyHiddenInput = styled('input')({
    clip: 'rect(0 0 0 0)',
    clipPath: 'inset(50%)',
    height: 1,
    overflow: 'hidden',
    position: 'absolute',
    bottom: 0,
    left: 0,
    whiteSpace: 'nowrap',
    width: 1,
});

const sendFile = async function (e) {
    const formElements = document.forms.instrumentUploadFile;
    const formData = new FormData(formElements);
    const fileToUpload = formData.get('file');
    const response = await fetch('http://deals.done.service.fvds.ru/api/dictionaries/InstrumentInfo/import', {
        method: 'POST',
        body: formData,
    });
}

export const InstrumentUploadButton = () => {
    const [fileName, setFileName] = useState('');

    return (
        <form id="instrumentUploadFile">
            <Button
                component="label"
                variant="outlined"
            >
                Загрузить инструменты
                <VisuallyHiddenInput
                    name='file'
                    type='file'
                    onChange={e => {
                        setFileName(e.target.files[0].name)
                    }}
                />
            </Button>

            <p>{fileName + ''}</p>

            <Button
                variant='contained'
                startIcon={<CloudUploadIcon />}
                type='submit'
                onClick={e => {
                    e.preventDefault()
                    sendFile(e);
                }}
            >
                Отправить
            </Button>
        </form>
    )
}