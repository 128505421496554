import { AppDispatch } from "../../app/store";
import { checkAuth } from "./auth.slice";

export const checkToken = function(dispatch: AppDispatch) {
    try {
        if (localStorage.getItem('token')) {
          dispatch(checkAuth());
        }
      } catch (e) {
        console.log(e);
      }
}