export function getDateFormat(date) {
    let year = date.getFullYear();
    let month = date.getMonth() + 1;
    let day = date.getDate();

    let dayFormat = day > 9 ? day : '0' + day;
    let monthFormat = month > 9 ? month : '0' + month;
    
    return `${dayFormat}.${monthFormat}.${year}`;
}

export function getTimeFormat(date) {
    let hours = date.getHours();
    let minutes = date.getMinutes();

    let hoursFormat = hours > 9 ? hours : '0' + hours;
    let minutesFormat = minutes > 9 ? minutes : '0' + minutes;

    return `${hoursFormat}:${minutesFormat}`;
}

export function getDateTimeFormat(date) {
    const [day, month, year] = getDateFormat(date).split('.');
    const [hours, minutes] = getTimeFormat(date).split(':');

    return `${year}-${month}-${day}T${hours}:${minutes}:${date.getSeconds}:${date.getMilliseconds()}Z`;
}

export function getNowTimeString() {
    const now = new Date(Date.now());
    return now.toJSON();
}