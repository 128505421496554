import { useNavigate } from "react-router-dom";
import PersonAddAlt1Icon from '@mui/icons-material/PersonAddAlt1';
import { Button } from "@mui/material";

import { ClientListConteiner } from "../../features/client/ui/ClientList"
import './ClientWidget.css';

export const ClientWidget = () => {
    const navigate = useNavigate();

    return (
        <div
            style={{
                display: 'flex',
                flexDirection: 'column',
                gap: '30px',
                alignItems: 'flex-start'
            }}
        >
            <Button
                onClick={() => {
                    navigate('/addNewClient');
                }}
                variant="outlined"
                className="AddClientButton"
                size='large'
                startIcon={<PersonAddAlt1Icon />}
            >
                Добавить клиента
            </Button>

            <ClientListConteiner />
        </div>
    )
}