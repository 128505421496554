import { useState } from "react";
import { useNavigate } from "react-router-dom";

import { IconButton } from "@mui/material";
import LogoutIcon from '@mui/icons-material/Logout';
import AddCircleOutlineIcon from '@mui/icons-material/AddCircleOutline';
import PersonIcon from '@mui/icons-material/Person';
import ListAltIcon from '@mui/icons-material/ListAlt';
import KeyboardDoubleArrowRightIcon from '@mui/icons-material/KeyboardDoubleArrowRight';

import { useAppDispatch } from "../../app/storeHooks";
import { logoutUser } from "../authentication/auth.slice";

import './Nav.css';



export const NavBar = () => {
    const dispatch = useAppDispatch();
    const navigate = useNavigate();

    const [opened, setOpened] = useState(false);

    return (
        <nav className={opened ? 'sidebar sidebar_opened' : 'sidebar'}>
            <NavBar.OpenSidebarButton opened={opened} setOpened={setOpened} />

            <ul className="fullwidth">
                <li
                    className="sidebar__button"
                    onClick={() => navigate('dealform')}
                >
                    <AddCircleOutlineIcon
                        className="sidebar__button__icon"
                    />
                    <span className={opened ? 'sidebar__button__label sidebar__button__label_opened' : 'sidebar__button__label'}>Новая сделка</span>
                </li>

                <li
                    className="sidebar__button"
                    onClick={() => navigate('deals')}
                >
                    <ListAltIcon
                        className="sidebar__button__icon"
                    />
                    <span className={opened ? 'sidebar__button__label sidebar__button__label_opened' : 'sidebar__button__label'}>Все сделки</span>
                </li>

                <li
                    className="sidebar__button"
                    onClick={() => navigate('clients')}
                >
                    <PersonIcon
                        className="sidebar__button__icon"
                    />
                    <span className={opened ? 'sidebar__button__label sidebar__button__label_opened' : 'sidebar__button__label'}>Клиенты</span>
                </li>
            </ul>

            <div
                className="sidebar__button sidebar__bottom-button"
                onClick={() => dispatch(logoutUser())}
            >
                <LogoutIcon
                    className="sidebar__button__icon"
                />
                <span className={opened ? 'sidebar__button__label sidebar__button__label_opened' : 'sidebar__button__label'}>Выйти</span>
            </div>
        </nav>
    )
}

NavBar.OpenSidebarButton = ({
    opened,
    setOpened
}: {
    opened: boolean,
    setOpened: (value: boolean) => any
}) => {

    return (
        <div
            className={`to-end ${opened ? "sidebar__opening-button sidebar__opening-button_opened" : "sidebar__opening-button"}`}>
            <IconButton
                onClick={() => setOpened(!opened)}
                size="large"
            >
                <KeyboardDoubleArrowRightIcon />
            </IconButton>
        </div>
    )
}