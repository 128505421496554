import { useState } from "react";

export const DEFAULT_ROWS_COUNT = 10;
export const ROWS_PER_PAGE_OPTIONS = [10, 25, 50, 100];

export const usePagination = () => {
    const [rowsPerPage, setRowsPerPage] = useState(DEFAULT_ROWS_COUNT);
    const [currentPageNumber, setCurrentPageNumber] = useState(0);

    let firstRowOnPageIndex = currentPageNumber * rowsPerPage;

    function changeRowsPerPage(count: number) {
        let newPageNumber = Math.max(
            0,
            Math.floor(firstRowOnPageIndex / count)
        );
        setRowsPerPage(count);
        setCurrentPageNumber(newPageNumber);
    }

    function changePageNumber(pageNumber: number) {
        setCurrentPageNumber(pageNumber);
    }

    return {
        firstRowOnPageIndex,
        rowsPerPage,
        currentPageNumber,
        changeRowsPerPage,
        changePageNumber
    }
}

