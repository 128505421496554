import { DealForm } from "../../features/deals/ui/DealForm";
import { AddNewDealForm } from "../../features/deals/ui/AddNewDealForm";

export const DealFormWidget = () => {
    return (
        <>
            <h2
                style={{ margin: '0 0 40px 0' }}
            >
                Новая сделка
            </h2>
            <AddNewDealForm />
        </>
    )
};