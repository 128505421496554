import api, { API_URL } from './../../../shared/api/request';

const GET_BROKER_INFO_ENDPOINT = 'employee';

export const getBrokerInfoRequest = async function() {
    return api.get(GET_BROKER_INFO_ENDPOINT)
        .then(response => {
            return response.data;
        })
        .catch(response => {
            return { };
        })
};