import { createAsyncThunk, createSlice } from "@reduxjs/toolkit"
import { changePasswordRequest, refreshRequest, loginRequest } from "./auth.service";

const initialState = {
    currentSession: {
        isAuth: false,
        accessToken: localStorage.getItem('token') ?? null,
    },
    isPasswordChanged: true,
};

export const loginUser = createAsyncThunk(
    'auth/loginUser',
    async ({ email, password }: LoginCredentials, thunkAPI) => {
        const response = await loginRequest(email, password);
        localStorage.setItem('token', response.accessToken);
        return response.accessToken;
    });

export const logoutUser = createAsyncThunk(
    'auth/logout',
    async () => {
        localStorage.removeItem('token');
    });

export const checkAuth = createAsyncThunk(
    'auth/checkAuth',
    async (params, thunkAPI) => {
        const response = await refreshRequest();
        if (response && response.accessToken) {
            localStorage.setItem('token', response.accessToken);
            return response.accessToken;
        } else {
            localStorage.removeItem('token');
            return thunkAPI.rejectWithValue('The session is over.')
        }
    });

export const changePassword = createAsyncThunk(
    'auth/changePassword',
    async (password: string, thunkAPI) => {
        const response = await changePasswordRequest(password);
        return response;
    });

export const authSlice = createSlice({
    name: 'auth',
    initialState,
    reducers: {
        setPasswordWasNotChanged(state) {
            state.isPasswordChanged = false;
        },
    },
    extraReducers: builder => {
        builder
            .addCase(loginUser.fulfilled, (state, action) => {
                state.currentSession.accessToken = action.payload;
                state.currentSession.isAuth = true;
            })
            .addCase(loginUser.rejected, (state, action) => {
                state.currentSession.accessToken = null;
                state.currentSession.isAuth = false;
            })
            .addCase(checkAuth.fulfilled, (state, action) => {
                state.currentSession.accessToken = action.payload;
                state.currentSession.isAuth = true;
            })
            .addCase(logoutUser.fulfilled, (state, action) => {
                state.currentSession.accessToken = null;
                state.currentSession.isAuth = false;
            })
            .addCase(checkAuth.rejected, (state, action) => {
                state.currentSession.accessToken = null;
                state.currentSession.isAuth = false;
            })
            .addCase(changePassword.fulfilled, (state, action) => {
                state.isPasswordChanged = true;
            })
    },
});

export const { setPasswordWasNotChanged } = authSlice.actions;
export default authSlice.reducer;