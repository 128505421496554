import { Navigate } from "react-router-dom"
import { useAppSelector } from "../../app/storeHooks"

export const AuthGateway = ({ children }: React.PropsWithChildren) => {
    const isAuthorized = useAppSelector(state => state.auth.currentSession.isAuth)

    if (!isAuthorized) {
        return <Navigate to="/login" replace={true} />
    }

    return (
        <>
            {children}
        </>
    )
}

export const WithAuthGateway = ({
    OnAuth, OnUnauth
}: {
    OnAuth: React.FC,
    OnUnauth: React.FC
}) => {
    const isAuthorized = useAppSelector(state => state.auth.currentSession.isAuth);

    if (isAuthorized) {
        return OnAuth;
    }

    return OnUnauth;
}

export const ProtectedRoute = ({ children }: React.PropsWithChildren) => WithAuthGateway({
    OnAuth: () => <>{children}</>,
    OnUnauth: () => <Navigate to="login" replace={true} />
});

