import { useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { LoadingButton } from "@mui/lab";

import { useDeleteClient } from "../clientAPISlice";


export const DeleteClientButton = ({ code }: { code: string }) => {
    const [deleteClient, metadata] = useDeleteClient();

    const navigate = useNavigate();
    useEffect(() => {
        if (metadata.isSuccess) {
            navigate('/clients');
        }
    }, [metadata.isSuccess]);

    return (
        <LoadingButton
            variant="outlined"
            color="error"
            sx={{
                float: 'right'
            }}

            onClick={() => deleteClient(code)}
            loading={metadata.isLoading}
        >
            Удалить клиента
        </LoadingButton>
    )
}