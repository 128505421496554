import { DealForm } from "./DealForm"
import { useAddDeal } from "../dealAPISlice";
import { Metadata } from "../../../api/api.dto";


export const AddNewDealForm = () => {
    const [addDeal, metadata] = useAddDeal();

    return (
        <DealForm
            deal={undefined}
            submitFunction={addDeal}
            metadata={metadata as Metadata}
        />
    )
}