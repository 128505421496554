import { Navigate } from 'react-router-dom';
import { useAppSelector } from '../../../app/storeHooks';

import './LoginPage.css';
import LoginForm from './LoginForm';
import { getLocation } from '../../../shared/helpers/useSaveURL';


const LoginPageLayout = () => {
    return (
        <div className="login">
            <h1>DealDone App</h1>
            <h2>Log in</h2>
            <LoginForm />
        </div>
    )
}

const LoginPage = () => {
    const isAuthorized = useAppSelector(state => state.auth.currentSession.isAuth);

    if (isAuthorized) {
        return <Navigate to={`${getURLToNavigate()}`} />
    }

    return <LoginPageLayout />
}

function getURLToNavigate() {
    const location = getLocation();

    if (location) {
        return location;
    }

    return '/';
}

export default LoginPage;