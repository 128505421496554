import { useEffect } from "react";
import { useLocation, useNavigate } from "react-router-dom";

const URL_IN_LOCAL_STORAGE_KEY = 'location';

export function useSaveURL() {
    const location = useLocation();

    useEffect(() => {
        localStorage.setItem(URL_IN_LOCAL_STORAGE_KEY, location?.pathname);
    }, [location?.pathname]);
}

export function getLocation() {
    return localStorage.getItem(URL_IN_LOCAL_STORAGE_KEY);
}

export function resetLocation() {
    localStorage.setItem(URL_IN_LOCAL_STORAGE_KEY, '');
}