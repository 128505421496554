import { Autocomplete, TextField } from "@mui/material"
import { useState } from "react";
import { useFetchInstruments } from "../../dealAPISlice";
import { Instrument } from "../../deal.dto";


export function InstrumentInput(props: any) {
    const { data } = useFetchInstruments({});
    const options = data?.map((instrument: Instrument) => {
        return {
            ...instrument,
            label: instrument.instrument
        }
    })

    const [inputValue, setInputValue] = useState('');


    return (
        <Autocomplete
            {...props}
            disablePortal
            options={options ?? []}

            value={props.value}
            onChange={props.onChange}

            inputValue={inputValue}
            onInputChange={(e, newInputValue) => {
                newInputValue = newInputValue.toUpperCase();
                setInputValue(newInputValue)
            }}

            renderInput={(params) =>
                <TextField
                    {...params}
                    required
                    fullWidth
                    label="Инструменты"
                    error={props?.error}
                    onBlur={props?.onBlur}
                    helperText={props?.helperText}
                />
            }
        />
    )
}