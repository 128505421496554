import { useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { useFormik } from "formik";
import * as Yup from 'yup';

import { FormGroup, TextField } from "@mui/material";
import { LoadingButton } from "@mui/lab";
import SendIcon from '@mui/icons-material/Send';

import './ClientForm.css';
import { Error } from '../../../shared/ui/Error';
import { Client, FormClient, mapClientFormToClient, mapClientToClientForm } from "../client.dto";
import { Metadata } from "../../../api/api.dto";


function getInitialValuesForEmptyClient() {
    return {
        locationAddress: 'Фактический адрес',
        registrationAddress: 'Юридический адрес',
        account: '12345678901234567890',
        corporateAccount: '12345678901234567890',
        inn: '7707083893',
        kpp: '123456789',
        ogrn: '1027700132195',
        rcbic: '123456789',
        code: '',
        email: '',
        name: ''
    } as FormClient
}

const validationSchema = Yup.object({
    email: Yup.string()
        .required('Введите email.')
        .email('Некорректный email.')
        .trim(),
    name: Yup.string()
        .required('Введите название.')
        .trim(),
    code: Yup.string()
        .required('Введите код.')
        .trim()
        .uppercase(),
    locationAddress: Yup.string()
        .trim(),
    registrationAddress: Yup.string()
        .trim(),
    account: Yup.string()
        .length(20, 'Состоит из 20 цифр.')
        .matches(/^[0-9]*$/, 'Введите цифры.'),
    corporateAccount: Yup.string()
        .length(20, 'Состоит из 20 цифр.')
        .matches(/^[0-9]*$/, 'Введите цифры.'),
    inn: Yup.string()
        .length(10, 'Состоит из 10 цифр.')
        .matches(/^[0-9]*$/, 'Введите цифры.'),
    kpp: Yup.string()
        .length(9, 'Состоит из 9 цифр.')
        .matches(/^[0-9]*$/, 'Введите цифры.'),
    ogrn: Yup.string()
        .length(13, 'Состоит из 13 цифр.')
        .matches(/^[0-9]*$/, 'Введите цифры.'),
    rcbic: Yup.string()
        .length(9, 'Состоит из 9 цифр.')
        .matches(/^[0-9]*$/, 'Введите цифры.'),
})


export const ClientForm = ({
    client, submitFunction, metadata
}: {
    client: Client | undefined,
    submitFunction: (client: Client) => any,
    metadata: Metadata
}) => {
    const formik = useFormik({
        initialValues: client ? mapClientToClientForm(client) : getInitialValuesForEmptyClient(),
        validationSchema,
        onSubmit: values => submitFunction(mapClientFormToClient(values))
    });

    const navigate = useNavigate();
    useEffect(() => {
        if (metadata.isSuccess) {
            navigate('/clients');
        }
    }, [metadata.isSuccess]);

    return (
        <form onSubmit={formik.handleSubmit}>
            <h2>{client ? formik.values.name : 'Новый клиент'}</h2>
            <FormGroup className="clientFormGroup" row>
                <TextField
                    type="email"
                    id="email"
                    name="email"
                    placeholder="Email"
                    label="Email"
                    required
                    margin="normal"
                    value={formik.values.email}
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                    error={!!formik.touched.email && Boolean(formik.errors.email)}
                    helperText={formik.touched.email ? formik.errors.email : null}
                />
                <TextField
                    id="name"
                    name="name"
                    placeholder="Name"
                    label="Название"
                    required
                    margin="normal"
                    value={formik.values.name}
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                    error={!!formik.touched.name && Boolean(formik.errors.name)}
                    helperText={formik.touched.name ? formik.errors.name : null}
                />
                <TextField
                    id="code"
                    name="code"
                    placeholder="Code"
                    label="Код"
                    required
                    margin="normal"
                    value={formik.values.code}
                    onChange={e => {
                        e.target.value = e.target.value.toUpperCase();
                        formik.handleChange(e);
                    }}
                    onBlur={formik.handleBlur}
                    error={!!formik.touched.code && Boolean(formik.errors.code)}
                    helperText={formik.touched.code ? formik.errors.code : null}
                />
            </FormGroup>

            <h3>Адрес</h3>
            <FormGroup className="clientFormGroup" row>
                <TextField
                    id="locationAddress"
                    name="locationAddress"
                    placeholder="Location Address"
                    label="Фактический адрес"
                    fullWidth
                    margin="normal"
                    value={formik.values.locationAddress}
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                    error={formik.touched.locationAddress && Boolean(formik.errors.locationAddress)}
                    helperText={formik.touched.locationAddress ? formik.errors.locationAddress : null}
                />
                <TextField
                    id="registrationAddress"
                    name="registrationAddress"
                    placeholder="Registration Address"
                    label="Юридический адрес"
                    fullWidth
                    margin="normal"
                    value={formik.values.registrationAddress}
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                    error={!!formik.touched.registrationAddress && Boolean(formik.errors.registrationAddress)}
                    helperText={formik.touched.registrationAddress ? formik.errors.registrationAddress : null}
                />
            </FormGroup>

            <h3>Банковская информация</h3>
            <FormGroup className="clientFormGroup" row>
                <TextField
                    id="account"
                    name="account"
                    placeholder="Банковский счет"
                    label="Банковский счет"
                    margin="normal"
                    value={formik.values.account}
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                    error={!!formik.touched.account && Boolean(formik.errors.account)}
                    helperText={formik.touched.account ? formik.errors.account : null}
                />

                <TextField
                    id="corporateAccount"
                    name="corporateAccount"
                    placeholder="Корпоративный счет"
                    label="Корпоративный счет"
                    margin="normal"
                    value={formik.values.corporateAccount}
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                    error={!!formik.touched.corporateAccount && Boolean(formik.errors.corporateAccount)}
                    helperText={formik.touched.corporateAccount ? formik.errors.corporateAccount : null}
                />

                <TextField
                    id="inn"
                    name="inn"
                    placeholder="ИНН"
                    label="ИНН"
                    margin="normal"
                    value={formik.values.inn}
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                    error={!!formik.touched.inn && Boolean(formik.errors.inn)}
                    helperText={formik.touched.inn ? formik.errors.inn : null}
                />

                <TextField
                    id="kpp"
                    name="kpp"
                    placeholder="КПП"
                    label="КПП"
                    margin="normal"
                    value={formik.values.kpp}
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                    error={!!formik.touched.kpp && Boolean(formik.errors.kpp)}
                    helperText={formik.touched.kpp ? formik.errors.kpp : null}
                />

                <TextField
                    id="ogrn"
                    name="ogrn"
                    placeholder="ОГРН"
                    label="ОГРН"
                    margin="normal"
                    value={formik.values.ogrn}
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                    error={!!formik.touched.ogrn && Boolean(formik.errors.ogrn)}
                    helperText={formik.touched.ogrn ? formik.errors.ogrn : null}
                />

                <TextField
                    id="rcbic"
                    name="rcbic"
                    placeholder="БИК"
                    label="БИК"
                    margin="normal"
                    value={formik.values.rcbic}
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                    error={!!formik.touched.rcbic && Boolean(formik.errors.rcbic)}
                    helperText={formik.touched.rcbic ? formik.errors.rcbic : null}
                />
            </FormGroup>

            {metadata.isError ? <Error message={metadata.error} /> : null}

            <LoadingButton
                type='submit'
                variant="contained"
                endIcon={<SendIcon />}
                sx={{
                    margin: '40px 0',
                }}
                loading={metadata.isLoading}
            >
                Подтвердить
            </LoadingButton>
        </form>
    )
}