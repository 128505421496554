import { useNavigate } from "react-router-dom";
import { IconButton } from "@mui/material";
import { ArrowBack } from "@mui/icons-material";

import { EditClientForm } from "../../features/client/ui/EditClientForm";


export const ClientCardWidget = () => {
    const navigate = useNavigate();

    return (
        <>
            <IconButton
                onClick={() => {
                    navigate('/clients');
                }}
            >
                <ArrowBack />
            </IconButton>
            <EditClientForm />
        </>
    )
}