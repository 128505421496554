import { useSelector } from "react-redux"

export const BrokerInfoComponent = () => {
    const brokerName = useSelector(state => state.broker.name);
    const brokerLastName = useSelector(state => state.broker.lastName);

    return(
        <div>
            {`${brokerName} ${brokerLastName}`}
        </div>
    )
}