import { useState } from "react";
import { getExchangeRateRequest } from "./cbrfService"
import './ExchangeRate.css';
import { useEffect } from "react";

const requestHandle = async (currency: string, dateTime: string) => {
    try {
        const response = await getExchangeRateRequest(currency, dateTime);
        return response;
    } catch (e) {
        console.log(e);
    }
    return 0;
}

export const ExchangeRate = ({
    currency,
    dateTime
}: { currency?: string, dateTime?: string }) => {

    const [rate, setRate] = useState(0);

    useEffect(() => {
        if (!currency || !dateTime) {
            return;
        }

        if (dateTime) {
            const dateTimeYear = new Date(dateTime).getFullYear();
            if (dateTimeYear <= 2000) {
                setRate(0);
                return;
            }
        }

        requestHandle(currency, dateTime)
            .then(rate => setRate(rate));

    }, [currency, dateTime]);

    if (!currency || !dateTime || !rate) {
        return null;
    }

    return (
        <div className="exchange-rate">
            <p>1,00 {currency}</p>
            <p>{rate?.toLocaleString('ru', { minimumFractionDigits: 4 })} RUB</p>
        </div>
    )
}

