import { Button } from "@mui/material";
import DownloadIcon from '@mui/icons-material/Download';
import { useDownloadDeals } from "../dealAPISlice";
import { DealFilterParams } from "./DealList/DealFilter";
import { useEffect, useRef, useState } from "react";
import { LoadingButton } from "@mui/lab";
import { filterParamsForQuery } from "./DealList/DealList";


export function DownloadDealsButton({ params }: { params: Partial<DealFilterParams> }) {
    const [download, metadata] = useDownloadDeals();
    const [url, setUrl] = useState('');
    const [filename, setFilename] = useState('');
    const aRef = useRef<HTMLAnchorElement>(null);

    useEffect(() => {
        if (url && filename && aRef.current) {
            aRef.current?.click();
        }
    }, [url, filename]);

    return (
        <>
            <LoadingButton
                variant="outlined"
                startIcon={<DownloadIcon />}
                loadingPosition="start"
                loading={metadata.isLoading}

                onClick={async () => {
                    const response = await download(filterParamsForQuery(params));
                    if ('data' in response) {
                        const newUrl = window.URL.createObjectURL(response.data);
                        setUrl(newUrl);
                        setFilename(getFileName(params));
                        window.URL.revokeObjectURL(url);
                    }
                }}
            >
                Скачать сделки
            </LoadingButton>

            <a
                ref={aRef}
                style={{
                    display: 'none'
                }}
                href={url}
                download={filename}
            />
        </>
    )
}

function getFileName(filterParams: Partial<DealFilterParams>) {
    const strParams: string[] = [];

    Object.entries(filterParams).forEach(entry => {
        if (entry[1] && !`${entry[1]}`.match(/\d{4}-\d{2}-\d{2}T/)) {
            strParams.push(`_${entry[0]}=${entry[1]}`);
        }
    });

    if (filterParams.To) {
        strParams.unshift(`_To=${new Date(filterParams.To).toLocaleString()}`)
    }
    if (filterParams.From) {
        strParams.unshift(`_From=${new Date(filterParams.From).toLocaleString()}`)
    }

    return `Deals${strParams.join('')}.xlsx`;
}