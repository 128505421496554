import { Grid, IconButton, Tooltip } from "@mui/material";
import { Error } from "../../shared/ui/Error";
import { LoadingButton } from "@mui/lab";
import SendIcon from '@mui/icons-material/Send';
import { useRequest } from "../../shared/WithRequestComponent/useRequest";
import { changePassword } from "../authentication/auth.slice";
import DoneIcon from '@mui/icons-material/Done';
import { useSelector } from "react-redux";
import HelpIcon from '@mui/icons-material/Help';
import { PasswordInput } from "../../shared/ui/PasswordInput";
import * as Yup from 'yup';
import { useFormik } from "formik";


const initialValues = {
    password: '',
    confirmPassword: ''
}

const validationSchema = Yup.object({
    password: Yup.string()
        .required()
        .min(12, 'Пароль должен быть не менее 12 символов.')
        .test('DoesPasswordHaveUppercase',
            "Пароль должен содержать заглавную букву",
            password => password !== password.toLocaleLowerCase())
        .test('DoesPasswordHaveLowercase',
            'Пароль должен содержать строчную букву',
            password => password !== password.toUpperCase())
        .matches(/\d+/, 'Пароль должен содержать цифру.')
        .matches(/[!"#$%&()*+,-./:;<=>?@[\\ \]^_`{|}~']/,
            'Пароль должен содержать специальный символ.'),
    confirmPassword: Yup.string().required()
})

export const ChangePasswordForm = () => {
    const [sendRequest, isLoading, response, error] = useRequest(changePassword);

    const isPasswordChanged = useSelector(state => state.auth.isPasswordChanged);

    const formik = useFormik({
        initialValues,
        validationSchema,
        onSubmit: values => sendRequest(values.password)
    });

    return (
        <>
            <h3>Изменить пароль</h3>
            {
                isPasswordChanged
                    ? null
                    : <Error message="Поменяйте пароль для завершения регистрации." />
            }
            <form
                style={{
                    'display': 'flex',
                    'flexDirection': 'column',
                    'alignItems': 'flex-start',
                    'width': '60%'
                }}
                onSubmit={formik.handleSubmit}
            >
                <Grid container spacing={2}>
                    <Grid item xs={10}>
                        <PasswordInput
                            id="password"
                            name="password"
                            placeholder="Новый пароль"
                            required
                            value={formik.values.password}
                            onChange={formik.handleChange}
                            onBlur={formik.handleBlur}
                            error={!!formik.touched.password && !!formik.errors.password}
                            helperText={formik.touched.password ? formik.errors.password : null}

                            sx={{
                                width: '100%'
                            }}
                        />
                    </Grid>

                    <Grid item xs={2}>
                        <Tooltip
                            style={{
                                width: 'fit-content',
                                margin: 'auto 0'
                            }}
                            fullwidth

                            title="Пароль должен быть не короче 12 символов, иметь строчную и заглавную буквы, а также цифру и спец.символ."
                            placement="right"
                        >
                            <IconButton>
                                <HelpIcon />
                            </IconButton>
                        </Tooltip>
                    </Grid>

                    <Grid item xs={10}>
                        <PasswordInput
                            id="confirmPassword"
                            name="confirmPassword"
                            placeholder="Подтверждение пароля"
                            required
                            value={formik.values.confirmPassword}
                            onChange={formik.handleChange}
                            onBlur={formik.handleBlur}
                            error={!!formik.touched.confirmPassword && formik.values.password !== formik.values.confirmPassword}
                            helperText={!!formik.touched.confirmPassword && formik.values.password !== formik.values.confirmPassword
                                ? 'Пароли не совпадают.' : null}

                            sx={{
                                width: '100%'
                            }}
                        />
                    </Grid>

                    <Grid item xs={10}>
                        <LoadingButton
                            type='submit'
                            variant="contained"
                            endIcon={
                                !isLoading && response && !error
                                    ? <DoneIcon />
                                    : <SendIcon />
                            }
                            sx={{
                                margin: '40px 0',
                                width: '100%'
                            }}
                            loading={isLoading}
                        >
                            Подтвердить
                        </LoadingButton>
                        <Error
                            message={error}
                        />
                    </Grid>
                </Grid>
            </form>
        </>
    )
}